import axios, { AxiosRequestHeaders } from "axios";
import { useEffect, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const usersListingTestOnlyFetcher: IApiFetcher = async ({
  utoken,
  omitUserAgent = false,
}: {
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<{
    data: any[];
    errors: any;
    meta: any;
  }>(`${HOSTNAME}${usersListingTestOnlyFetcher.url}`, {
    headers,
  });
  return response.data;
};
usersListingTestOnlyFetcher.url = "/wp-json/wp/v3/users/listing-test-only";
usersListingTestOnlyFetcher.method = 'GET';

const UsersListingTestOnly: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let isActive = true;
    if (!loginData?.token) return;

    if (isActive)
      usersListingTestOnlyFetcher({ utoken: loginData.token, omitUserAgent: true }).then(
        (data) => {
          setIsLoading(false);
          setData(JSON.stringify(data, null, 2));
        }
      );

    return () => {
      isActive = false;
    };
  }, [loginData]);

  return (
    <div>
      <h3>UsersListingTestOnly</h3>

      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

UsersListingTestOnly.fetcher = usersListingTestOnlyFetcher;

export default UsersListingTestOnly;
