export const artistCatIds = [
  { id: 503, name: "Arcangelos Chamber Ensemble" },
  { id: 504, name: "Aeoliah" },
  { id: 505, name: "Alan Tower" },
  { id: 506, name: "Amoraea Dreamseed" },
  { id: 507, name: "Andrzej Rejman" },
  { id: 508, name: "Bob Volkman" },
  { id: 509, name: "Brad Allen" },
  { id: 510, name: "Byron Metcalf" },
  { id: 511, name: "Catherine Marie Charlton" },
  { id: 512, name: "Craig Padilla" },
  { id: 513, name: "Dean Evenson" },
  { id: 514, name: "Deborah Martin" },
  { id: 515, name: "Don Peyote" },
  { id: 516, name: "Eluv" },
  { id: 517, name: "Erik Berglund" },
  { id: 518, name: "Felix Rodriguez" },
  { id: 519, name: "Frank Danna" },
  { id: 520, name: "Gerald Jay Markoe &amp; Ancient Brotherhood" },
  { id: 521, name: "Ilona Selke and Don Paris" },
  { id: 522, name: "Inlakesh" },
  { id: 523, name: "J. S. Epperson" },
  { id: 524, name: "Jon Jenkins" },
  { id: 525, name: "Kieran DeVerniero" },
  { id: 527, name: "Lenore Paxton &amp; Phillip Siadi" },
  { id: 528, name: "Matthew Sigmon &amp; Julie Anderson" },
  { id: 529, name: "Micah Sadigh, Ph.D." },
  { id: 530, name: "Michael Maricle" },
  { id: 531, name: "Laura Nashman" },
  { id: 532, name: "Paul Sihon" },
  { id: 533, name: "Ray Dretske" },
  { id: 534, name: "Richard Roberts (a.k.a. Zero Ohms)" },
  { id: 535, name: "Rick Borgia" },
  { id: 536, name: "Steve MacLean" },
  { id: 537, name: "Steven Halpern" },
  { id: 538, name: "ThunderBeat" },
  { id: 539, name: "William Whitten" },
  { id: 540, name: "Patty Ray Avalon" },
  { id: 541, name: "Byron Metcalf, Ph.D." },
  { id: 542, name: "Dr. C. Norman Shealy" },
  { id: 543, name: "Joe Gallenberger, Ph.D." },
  { id: 544, name: "Mark Macy" },
  { id: 545, name: "Barry Oser" },
  { id: 546, name: "Peter Russell" },
  { id: 547, name: "Patty Summers" },
  { id: 555, name: "Scott Bucklin" },
  { id: 556, name: "Barry Goldstein" },
  { id: 557, name: "Erik W�llo" },
  { id: 561, name: "Jeffree Clarkson" },
  { id: 563, name: "Alpha Wave Movement (aka Gregory Kyryluk)" },
  { id: 566, name: "Tenzin Wangyal Rinpoche" },
  { id: 572, name: "Peter Jack Rainbird" },
  { id: 574, name: "William Buhlman" },
  { id: 577, name: "Frederic Delarue" },
  { id: 579, name: "John Gregorius" },
  { id: 581, name: "Jonn Serrie" },
  { id: 582, name: "Michael Moon" },
  { id: 583, name: "Michel Genest" },
  { id: 584, name: "Scott M. Taylor, Ed.D." },
  { id: 587, name: "Gary Malkin" },
  { id: 589, name: "John Alonso" },
  { id: 591, name: "Naasko" },
  { id: 592, name: "Max Corbacho" },
  { id: 593, name: "Joshua Leeds" },
  { id: 594, name: "Pablo Pel�ez" },
  { id: 595, name: "Tracy Chappell (a.k.a. thunderjack)" },
  { id: 596, name: "Carolyn M. Ball, MA, LPC" },
  { id: 597, name: "Lee Stone" },
  { id: 598, name: "Chronotope Project" },
  { id: 599, name: "Winter Robinson" },
  { id: 600, name: "Howard Givens" },
  { id: 602, name: "J Arif Verner" },
  { id: 9433, name: "Giesemann, Suzanne" },
  { id: 10559, name: "Deepak Chopra" },
  { id: 10561, name: "Sverre Knut Johansen" },
  { id: 10857, name: "Luigi Sciambarella" },
  { id: 10926, name: "Jackie Haverty" },
  { id: 11032, name: "Marc Allen" },
  { id: 11126, name: "Deborah Bromley" },
  { id: 11172, name: "Corinne Zupko" },
  { id: 11431, name: "Glenn Harrold" },
  { id: 11473, name: "T!M FREKE" },
  { id: 11527, name: "Anita Moorjani" },
  { id: 11608, name: "Andrej Hrvatin - Nimetu" },
  { id: 11659, name: "Thomas Mooneagle" },
  { id: 11674, name: "Mark Seelig" },
  { id: 11973, name: "Garrett Stevens" },
  { id: 12399, name: "Marvin Allen" },
  { id: 13330, name: "David Helpling" },
  { id: 13337, name: "Ralph Metzner" },
  { id: 13421, name: "Oliver, Jim" },
  { id: 14760, name: "Suresh Ramaswamy" },
  { id: 14864, name: "David Lear" },
  { id: 14889, name: "David Young" },
  { id: 14890, name: "Sam Rosenthal" },
  { id: 14891, name: "jarguna" },
  { id: 14892, name: "Traci Stein, PhD MPH" },
  { id: 14936, name: "Dr. Lotte Valentin" },
];
