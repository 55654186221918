import axios, { AxiosRequestHeaders } from "axios";
import { useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const usersFavoritesGetGet: IApiFetcher = async ({
  utoken,
  omitUserAgent = false,
}: {
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<any>(`${HOSTNAME}${usersFavoritesGetGet.url}`, {
    headers,
  });
  return response.data;
};
usersFavoritesGetGet.url = "/wp-json/wp/v3/users/favorites/get";
usersFavoritesGetGet.method = 'GET';

const UsersFavoritesGet: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onFetch = () => {
    if (!loginData?.token) return;

    setIsLoading(true);
    usersFavoritesGetGet({
      utoken: loginData.token,
      omitUserAgent: true,
    }).then((data) => {
      setIsLoading(false);
      setData(JSON.stringify(data, null, 2));
    });
  };

  return (
    <div>
      <h3>UsersFavoritesGet</h3>

      <button onClick={onFetch}>Fetch</button>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

UsersFavoritesGet.fetcher = usersFavoritesGetGet;

export default UsersFavoritesGet;
