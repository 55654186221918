import { useEffect, useState } from "react";
import {
  FFLayoutDebug,
  FFPostConfig,
  FFTemplateConfig,
  IDebugResponse,
  IS3Config,
} from "./debug-models";
import classes from "./DebuggerApp.module.css";

declare var FF_LAYOUT_DEBUG: FFLayoutDebug | undefined;

const debuggerAppMinimize = localStorage.getItem("debuggerAppMinimize");

const DebuggerApp = () => {
  const [minimized, setMinimized] = useState<boolean>(
    debuggerAppMinimize === "true"
  );
  const layout = FF_LAYOUT_DEBUG;

  const [templateConfigs, setTemplateConfigs] = useState<FFTemplateConfig[]>(
    []
  );
  const [postConfigs, setPostConfigs] = useState<FFPostConfig[]>([]);
  const [s3Config, setS3Config] = useState<IS3Config | undefined>();
  const [websiteMode, setWebsiteMode] = useState<string>('');
  useEffect(() => {
    fetch("/wp-json/hemi/debug/ff-templates")
      .then((res) => res.json())
      .then(({ templates, posts, s3, websiteMode }: IDebugResponse) => {
        setTemplateConfigs(templates);
        setPostConfigs(posts);
        setS3Config(s3);
        setWebsiteMode(websiteMode)
      });
  }, []);

  const onShowDebugger = () => {
    localStorage.removeItem("debuggerAppMinimize");
    setMinimized(false);
  };

  const onMinimizeDebugger = () => {
    localStorage.setItem("debuggerAppMinimize", "true");
    setMinimized(true);
  };

  if (!layout) return null;

  if (minimized)
    return (
      <div className={classes.rootMinimized}>
        <button onClick={onShowDebugger}>Show Debugger</button>
      </div>
    );

  return (
    <div className={classes.root}>
      <div style={{ textAlign: "right" }}>
        <button onClick={onMinimizeDebugger}>Minimize Debugger</button>
      </div>
      <div className={classes.properties}>
        <div>layout: {layout.layout}</div>
        <div>titlebar: {layout.titlebar}</div>
        <div>viewName: {layout.viewName}</div>
        <div>viewType: {layout.viewType}</div>
        <div>viewSubType: {layout.viewSubType || "undefined"}</div>
        <div>postCount: {postConfigs.length}</div>
        <div>s3 URL: {s3Config?.uploadsBucketUrl}</div>
        <div>websiteMode: {websiteMode}</div>
      </div>
      {templateConfigs.map((config) => {
        const name = config.option_name.slice(19);
        const posts = postConfigs.filter(
          (p) => p.meta_value.settings && p.meta_value.settings.layout === name
        );

        return (
          <div key={config.option_id}>
            <div className={classes.row}>
              <div>{config.option_id}</div>
              <div>{name}</div>
              <div>{config.option_value.name}</div>
              <div>{posts.length}</div>
            </div>
            <div className={classes.post}>
              {posts.map((p) => (
                <span key={p.post_id}>
                  <a href={p.permalink}>{p.post_id}</a>
                </span>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DebuggerApp;
