import axios, { AxiosRequestHeaders } from "axios";
import { useRef, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const playlistsGet: IApiFetcher = async ({
  utoken,
  playlistIds,
  omitUserAgent = false,
}: {
  utoken: string;
  playlistIds?: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<any>(`${HOSTNAME}${playlistsGet.url}`, {
    headers,
    params: {
      pl_id: playlistIds
    }
  });
  return response.data;
};
playlistsGet.url = "/wp-json/wp/v3/playlists/get";
playlistsGet.method = 'GET';

const PlaylistsGet: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const playlistIdsRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onFetch = () => {
    if (!loginData?.token) return;
    if(!playlistIdsRef.current) return;

    setIsLoading(true);
    playlistsGet({
      utoken: loginData.token,
      playlistIds: playlistIdsRef.current.value || undefined,
      omitUserAgent: true,
    }).then((data) => {
      setIsLoading(false);
      setData(JSON.stringify(data, null, 2));
    });
  };

  return (
    <div>
      <h3>PlaylistsGet</h3>
      <label>Playlist IDs: </label>
      <input type="text" ref={playlistIdsRef} />
      <button onClick={onFetch}>Fetch</button>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

PlaylistsGet.fetcher = playlistsGet;

export default PlaylistsGet;
