import axios, { AxiosRequestHeaders } from "axios";
import { useEffect, useState } from "react";
import { IArtistListItem, ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const artistsListFetcher: IApiFetcher = async ({
  utoken,
  omitUserAgent = false,
}: {
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<{
    data: IArtistListItem[];
    errors: any;
    meta: any;
  }>(`${HOSTNAME}${artistsListFetcher.url}`, {
    headers,
  });
  return response.data;
};
artistsListFetcher.url = "/wp-json/wp/v3/artists/list";
artistsListFetcher.method = 'GET';

const ArtistsList: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let isActive = true;
    if (!loginData?.token) return;

    if (isActive)
      artistsListFetcher({ utoken: loginData.token, omitUserAgent: true }).then(
        (data) => {
          setIsLoading(false);
          setData(JSON.stringify(data, null, 2));
        }
      );

    return () => {
      isActive = false;
    };
  }, [loginData]);

  return (
    <div>
      <h3>ArtistsList</h3>

      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

ArtistsList.fetcher = artistsListFetcher;

export default ArtistsList;
