import React from "react";
import ReactDOM from "react-dom";
import DebuggerApp from "./DebuggerApp";
import "./site.scss";
import "./custom-style-one.css";
import "./media.css";
import "./hemisync.css";
import "./index.css";
import "./default-footer.css";
import "./css/_colors.scss";
import "./buttons.css";
import "./css/_carousel.scss";
import "./css/_forms.scss";
import "./ac-components.css";
import "./widgets.css";
import "./layouts.scss";
import "./customizer.scss";
import "./css/page-courses.scss";
import "./css/search.scss";
import { IResponse } from "./models";
import ProductsDisplay from "./components/StoreBrowser/ProductsDisplay";
import {StoreBrowserFinal} from "./components/StoreBrowser/StoreBrowser";
import SpecialToolsApp from "./SpecialToolsApp";
import SearchApp from "./SearchApp";

declare var __hgd_all_products: IResponse;

const storeBrowserElem = document.querySelector(".store-browser");
if (storeBrowserElem) {
  ReactDOM.render(
    <React.StrictMode>
      <StoreBrowserFinal />
    </React.StrictMode>,
    storeBrowserElem
  );
}

const searchAppElem = document.querySelector(".search-app");
if (searchAppElem) {
  ReactDOM.render(
    <React.StrictMode>
      <SearchApp />
    </React.StrictMode>,
    searchAppElem
  );
}

const specialToolsAppDiv = document.querySelector(".special-tools-app");
if (specialToolsAppDiv) {
  ReactDOM.render(
    <React.StrictMode>
      <SpecialToolsApp />
    </React.StrictMode>,
    specialToolsAppDiv
  );
}

const productListingElems = document.querySelectorAll(".happ-products-display");

productListingElems.forEach((el) => {
  ReactDOM.render(
    <ProductsDisplay slicedProducts={__hgd_all_products.products} />,
    el
  );
});
const showDebugger = localStorage.getItem("debuggerApp");
const debuggerApp = document.querySelector(".debugger-app");

if (showDebugger === "on" && debuggerApp) {
  ReactDOM.render(
    <React.StrictMode>
      <DebuggerApp />
    </React.StrictMode>,
    debuggerApp
  );
}
