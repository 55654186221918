import { FormEventHandler, useEffect, useRef, useState } from "react";
import { ILoginData2, ISampleUser } from "../../models";
import { login } from "./api-utils";
import classes from "./ApiTesterApp.module.scss";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import LibraryAdd from "./subapps/LibraryAdd";
import LibraryDel from "./subapps/LibraryDel";
import UsersAccountDeletion from "./subapps/UsersAccountDeletion";
import PlaylistsGetshared from "./subapps/PlaylistsGetshared";
import LibraryGet from "./subapps/LibraryGet";
import UsersRegister from "./subapps/UsersRegister";
import ArtistsList from "./subapps/ArtistsList";
import ArtistsArtistAlbums from "./subapps/ArtistsArtistAlbums";
import ArtistsArtistsByApp from "./subapps/ArtistsArtistsByApp";
import ArtistsArtistsByGenre from "./subapps/ArtistsArtistsByGenre";
import ArtistsGetShort from "./subapps/ArtistsGetShort";
import ArtistsGetFull from "./subapps/ArtistsGetFull";
import ArtistsSubscriptionGet from "./subapps/ArtistsSubscriptionGet";
import ArtistsSubscriptionAdd from "./subapps/ArtistsSubscriptionAdd";
import ArtistsSubscriptionDel from "./subapps/ArtistsSubscriptionDel";
import CategoriesFetch from "./subapps/CategoriesFetch";
import CategoriesGetTrialId from "./subapps/CategoriesGetTrialId";
import UsersListingTestOnly, {
  usersListingTestOnlyFetcher,
} from "./subapps/UsersListingTestOnly";
import UsersActiveSubscription from "./subapps/UsersActiveSubscription";
import UsersGetCategories from "./subapps/UsersGetCategories";
import UsersGetOrdered from "./subapps/UsersGetOrdered";
import UsersGetTrialCost from "./subapps/UsersGetTrialCost";
import UsersGetDaily from "./subapps/UsersGetDaily";
import UsersHome from "./subapps/UsersHome";
import { useQuery } from "@tanstack/react-query";
import UsersProfileGetData from "./subapps/UsersProfileGetData";
import UsersUpdate from "./subapps/UsersUpdate";
import UsersApplepayValidate from "./subapps/UsersApplepayValidate";
import TracksTopSales from "./subapps/TracksTopSales";
import UsersProfileMeta from "./subapps/UsersProfileMeta";
import UsersFavoritesGet from "./subapps/UsersFavoritesGet";
import UsersFavoritesAdd from "./subapps/UsersFavoritesAdd";
import UsersFavoritesDel from "./subapps/UsersFavoritesDel";
import UsersGooglepayValidate from "./subapps/UsersGooglepayValidate";
import UsersLoginApple from "./subapps/UsersLoginApple";
import UsersUpdateSubscription from "./subapps/UsersUpdateSubscription";
import PlaylistsGetrecommendedU from "./subapps/PlaylistsGetrecommendedU";
import PlaylistsGet from "./subapps/PlaylistsGet";
import TracksTopRatedAlbums from "./subapps/TracksTopRatedAlbums";
import TracksGetApps from "./subapps/TracksGetApps";
import TracksGetGenres from "./subapps/TracksGetGenres";
import TracksAlbumInfo from "./subapps/TracksAlbumInfo";
import TracksAlbum from "./subapps/TracksAlbum";
import TracksGetLangs from "./subapps/TracksGetLangs";
import TracksSetLang from "./subapps/TracksSetLang";
import TracksExploreSearch from "./subapps/TracksExploreSearch";
import TracksNewAlbums from "./subapps/TracksNewAlbums";
import TracksAlbumsByGenre from "./subapps/TracksAlbumsByGenre";
import UsersCategories from "./subapps/UsersCategories";

declare var sample_users: ISampleUser[];

export interface IApiFetcher {
  (args: any): Promise<any>;
  url: string;
  method?: "GET" | "POST";
}

export type ApiTesterComponent = {
  ({ loginData }: { loginData: ILoginData2 | null }): JSX.Element;
  fetcher: IApiFetcher;
};

const loginDataLS = window.localStorage.getItem("api-tester-login");
let initialLoginData: ILoginData2 | null = null;
if (loginDataLS) {
  initialLoginData = JSON.parse(loginDataLS);
}

const components: ApiTesterComponent[] = [
  ArtistsArtistsByApp,
  ArtistsArtistsByGenre,
  ArtistsArtistAlbums,
  ArtistsGetShort,
  ArtistsGetFull,
  ArtistsSubscriptionGet,
  ArtistsSubscriptionAdd,
  ArtistsSubscriptionDel,
  CategoriesFetch,
  CategoriesGetTrialId,
  ArtistsList,
  LibraryAdd,
  LibraryDel,
  LibraryGet,
  PlaylistsGet,
  PlaylistsGetrecommendedU,
  PlaylistsGetshared,
  TracksAlbum,
  TracksAlbumsByGenre,
  TracksAlbumInfo,
  TracksExploreSearch,
  TracksGetApps,
  TracksGetGenres,
  TracksGetLangs,
  TracksNewAlbums,
  TracksSetLang,
  TracksTopSales,
  TracksTopRatedAlbums,
  UsersActiveSubscription,
  UsersCategories,
  UsersFavoritesGet,
  UsersFavoritesAdd,
  UsersFavoritesDel,
  UsersGetCategories,
  UsersGetDaily,
  UsersGetTrialCost,
  UsersGetOrdered,
  UsersLoginApple,
  UsersProfileGetData,
  UsersProfileMeta,
  UsersHome,
  UsersUpdateSubscription,
  UsersListingTestOnly,
  UsersAccountDeletion,
  UsersApplepayValidate,
  UsersGooglepayValidate,
  UsersRegister,
  UsersUpdate,
];

const ApiTesterApp = ({ base = "" }: { base: string }) => {
  const [email, setEmail] = useState<string>("");
  const userSelectRef = useRef<HTMLSelectElement>(null);
  const [switcherOpen, setSwitcherOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { isLoading: listingIsLoading, data: listingData } = useQuery(
    ["usersListingTestOnlyFetcher"],
    () =>
      usersListingTestOnlyFetcher({
        utoken: loginData?.token,
        omitUserAgent: true,
      }) as Promise<{ ID: string; user_email: string }[]>,
    { networkMode: "always" }
  );

  const [loginData, setLoginData] = useState<ILoginData2 | null>(
    initialLoginData
  );

  const doLogin = async (email: string) => {
    const response = await login({ email, password: "password" }, true);
    setLoginData(response.data.data);
    window.localStorage.setItem(
      "api-tester-login",
      JSON.stringify(response.data.data)
    );
  };

  const onChangeUser: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    let activeEmail: string = email;
    if (!email && userSelectRef.current)
      activeEmail = userSelectRef.current.value;
    if (activeEmail) doLogin(activeEmail);
  };

  useEffect(() => {
    setSwitcherOpen(false);
  }, [pathname]);

  // if (listingIsLoading) return <p>Loading...</p>;

  return (
    <div className={classes.root}>
      <h2>API Tester App</h2>

      <section>
        <form onSubmit={onChangeUser}>
          <div>
            <label htmlFor="user-email">Change User</label>
          </div>
          <input
            type="text"
            id="user-email"
            placeholder="Email or UID"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            list="data-email"
          />
          <datalist id="data-email">
            <select name="user-email">
              {sample_users.map((user) => (
                <option key={user.ID}>{user.user_email}</option>
              ))}
            </select>
          </datalist>
          <div>
            <input type="submit" value="Set User" />
          </div>
        </form>
        <pre>{JSON.stringify(loginData, null, 2)}</pre>
        <ul>
          {!listingIsLoading && listingData?.map((item) => (
            <li key={item.ID} onClick={() => doLogin(item.user_email)}>
              {item.ID}: {item.user_email}
            </li>
          ))}
        </ul>
      </section>

      <div className={classes.apiNavLinks}>
        <button
          onClick={() => {
            setSwitcherOpen((prev) => !prev);
          }}
        >
          {switcherOpen ? "Collapse" : "Open"} Switcher
        </button>
        {switcherOpen &&
          components.map((Cmp) => (
            <Link
              key={Cmp.fetcher.url}
              to={Cmp.fetcher.url.replace("/wp-json/wp/v3", "").slice(1)}
            >
              <div>
                {Cmp.fetcher.method || "GET"} {Cmp.fetcher.url}
              </div>
            </Link>
          ))}
      </div>

      <Routes>
        {components.map((Cmp) => (
          <Route
            key={Cmp.fetcher.url}
            path={Cmp.fetcher.url.replace("/wp-json/wp/v3", "")}
            element={<Cmp loginData={loginData} />}
          />
        ))}
      </Routes>
    </div>
  );
};

export default ApiTesterApp;
