import axios, { AxiosRequestHeaders } from "axios";
import { ChangeEventHandler, useState } from "react";
import { artistCatIds } from "../../../data-for-test";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const fetcher: IApiFetcher = async ({
  app_id,
  utoken,
  omitUserAgent = false,
}: {
  app_id: number;
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<any>(`${HOSTNAME}${fetcher.url}`, {
    headers,
    params: {
      app_id
    }
  });
  return response.data;
};
fetcher.url = "/wp-json/wp/v3/artists/artists_by_app";
fetcher.method = 'GET';

const ArtistsArtistsByApp: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const [data, setData] = useState<string>("");
  const [appId, setAppId] = useState<string>("0");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onFetch = () => {
    if (!loginData?.token) return;

    setIsLoading(true);
    fetcher({
      app_id: +appId,
      utoken: loginData.token,
      omitUserAgent: true,
    }).then((data) => {
      setIsLoading(false);
      setData(JSON.stringify(data, null, 2));
    });
  };

  const onArtistChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setAppId(e.target.value);
  };

  return (
    <div>
      <h3>ArtistsArtistsByApp</h3>

      <select value={appId} onChange={onArtistChange}>
        <option key="0" value="0">
          None
        </option>
        {artistCatIds.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      <button onClick={onFetch}>Fetch</button>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

ArtistsArtistsByApp.fetcher = fetcher;

export default ArtistsArtistsByApp;
