import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import {
  BrowserRouter,
  HashRouter,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ICategory, IProductInfo, IResponse } from "../../models";
import Pager from "../../Pager";
import ProductsDisplay from "./ProductsDisplay";
import StoreBrowserHome from "./StoreBrowserHome";
import classes from "./StoreBrowser.module.scss";

const PER_PAGE = 20;

const getSubcategories = (
  cats: ICategory[],
  allCategories: ICategory[]
): ICategory[] => {
  if (cats.length === 0) return [];
  const parentIds = cats.map((c) => c.term_id);
  const subCategories = allCategories.filter((c) =>
    parentIds.some((id) => c.parent === id)
  );
  return [...cats, ...getSubcategories(subCategories, allCategories)];
};

const getDirectSubcategories = (
  parent: ICategory,
  allCategories: ICategory[]
): ICategory[] => {
  const subCategories = allCategories.filter(
    (c) => c.parent === parent.term_id
  );

  return subCategories;
};

const decodeHTMLEntities = (text: string) => {
  const el = document.createElement("div");
  el.innerHTML = text;
  return el.textContent;
};

const FILTERS = [
  // { slug: "by-artist" },
  { slug: "by-language" },
  { slug: "by-real-life-applications" },
  { slug: "by-genre" },
  { slug: "by-series" },
];

const SORT_TYPES = [
  "Most Reviews",
  "A-Z",
  "Lowest Price",
  "Highest Price",
  "Best Rating",
] as const;

type SORT_TYPE = typeof SORT_TYPES[number];

export function StoreBrowserInner({ base = "" }: { base?: string }) {
  const [allProducts, setAllProducts] = useState<IProductInfo[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<IProductInfo[]>([]);
  const [slicedProducts, setSlicedProducts] = useState<IProductInfo[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [showCategoryFilter, setShowCategoryFilter] = useState<boolean>(false);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const page = Number(searchParams.get("page"));
  const sortType: SORT_TYPE = searchParams.get("sortType") as SORT_TYPE;
  const initialTerms = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return (
      searchParams
        .get("terms")
        ?.split(",")
        .filter((s) => /^[0-9]+$/.test(s))
        .map(Number) || []
    );
  }, [location]);

  const [pageCategory, setPageCategory] = useState<ICategory | undefined>(
    undefined
  );
  const [selectedCategories, setSelectedCategories] = useState<ICategory[]>([]);
  const [parentCategory, setParentCategory] = useState<ICategory | null>(null);
  const [filters, setFilters] = useState<ICategory[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/wp-json/hemi/v1/all-products");
      const data = await response.json();

      return data as IResponse;
    };

    fetchData().then((data) => {
      setAllProducts(data.products);
      const theProducts = data.products.filter((p) =>
        initialTerms.every((el) =>
          p.categories.map((c2) => c2.term_id).includes(el)
        )
      );
      setFilteredProducts(theProducts);
      setCategories(data.categories);
    });
  }, [initialTerms]);

  const pathSegments = useMemo(
    () =>
      location.pathname
        .split("/")
        .slice(1)
        .filter((seg) => seg !== ""),
    [location]
  );

  useEffect(() => {
    const cat = categories.find(
      (c) => c.slug === pathSegments[pathSegments.length - 1]
    );
    setPageCategory(cat);

    const subCategories = cat ? getSubcategories([cat], categories) : [];

    setSelectedCategories(subCategories);

    const parentCat = cat?.parent
      ? categories.find((c) => c.term_id === cat.parent) || null
      : null;
    setParentCategory(parentCat);

    if (categories.length && cat) {
      setFilters(
        FILTERS.filter((f) => f.slug !== cat?.slug).map(
          (f) => categories.find((c) => c.slug === f.slug) as ICategory
        )
      );
    }
  }, [categories, pathSegments, parentCategory]);

  useEffect(() => {
    const theProducts = filteredProducts.filter((p) =>
      initialTerms.every((el) =>
        p.categories.map((c2) => c2.term_id).includes(el)
      )
    );

    const sortedProducts = [...theProducts];
    switch (sortType) {
      case "A-Z":
        sortedProducts.sort((a, b) =>
          a.post_title.trim().toLowerCase() < b.post_title.trim().toLowerCase()
            ? -1
            : 1
        );
        break;
      case "Best Rating":
        sortedProducts.sort((a, b) =>
          a.average_rating < b.average_rating ? 1 : -1
        );
        break;
      case "Lowest Price":
        sortedProducts.sort((a, b) => (+a.price < +b.price ? -1 : 1));
        break;
      case "Highest Price":
        sortedProducts.sort((a, b) => (+a.price < +b.price ? 1 : -1));
        break;
      case "Most Reviews":
        sortedProducts.sort((a, b) =>
          a.review_count < b.review_count ? 1 : -1
        );
        break;
      default:
        break;
    }

    setSlicedProducts(
      sortedProducts.slice(page * PER_PAGE, (page + 1) * PER_PAGE)
    );
  }, [page, filteredProducts, initialTerms, sortType]);

  useEffect(() => {
    const termFilteredProducts = allProducts.filter((p) =>
      initialTerms.every((el) =>
        p.categories.map((c2) => c2.term_id).includes(el)
      )
    );
    if (selectedCategories.length !== 0) {
      const theProducts = termFilteredProducts.filter((p) => {
        return p.categories.some((c) =>
          selectedCategories.map((c2) => c2.term_id).includes(c.term_id)
        );
      });
      setFilteredProducts(theProducts);
    } else {
      setFilteredProducts(termFilteredProducts);
    }
  }, [selectedCategories, allProducts, initialTerms]);

  let pageCount = filteredProducts.length / PER_PAGE;
  pageCount = Math.floor(pageCount) + (pageCount % 1 === 0 ? 0 : 1);

  const parentLinkTitle = parentCategory ? parentCategory.name : "All";
  const parentLink = parentCategory ? parentCategory.slug : "";

  if (selectedCategories.length === 0) {
    return <StoreBrowserHome base={base} />;
  }

  const pageCategoryName = pageCategory
    ? decodeHTMLEntities(pageCategory.name)
    : "";

  const sidebarElementClasses = [classes.sidebarElement];
  if (showCategoryFilter) {
    sidebarElementClasses.push(classes.sidebarElementShowing);
  }

  const sideBarElement: JSX.Element = (
    <div className={sidebarElementClasses.join(" ")}>
      <div>
        {location.pathname !== "/" && (
          <h2>
            <Link to={parentLink}>{parentLinkTitle}</Link>
          </h2>
        )}

        <h3>{pageCategoryName}</h3>
        <div>
          {categories &&
            filters
              .map((f) => {
                const subCategories = getSubcategories([f], categories);
                const directSubcategories = getDirectSubcategories(
                  f,
                  categories
                )
                  .map((c) => {
                    const subCategoryCount = filteredProducts.filter((p) =>
                      p.categories.map((pc) => pc.term_id).includes(c.term_id)
                    ).length;
                    return { c, subCategoryCount };
                  })
                  .filter(({ subCategoryCount }) => subCategoryCount);

                return { category: f, subCategories, directSubcategories };
              })
              .filter(({ directSubcategories }) => {
                return !!directSubcategories.length;
              })
              .map(({ category, directSubcategories }) => {
                directSubcategories.sort(
                  ({ subCategoryCount: a }, { subCategoryCount: b }) => {
                    return b - a;
                  }
                );
                return (
                  <div key={category.term_id}>
                    <div className={classes.filterTitle}>
                      {decodeHTMLEntities(category.name)} [
                      {directSubcategories.length}]
                    </div>
                    <div>
                      {directSubcategories.map(({ c, subCategoryCount }) => {
                        return (
                          <div className={classes.subCategory} key={c.term_id}>
                            <input
                              readOnly
                              type="checkbox"
                              id={`cat-${c.term_id}`}
                              checked={initialTerms?.includes(c.term_id)}
                              onClick={() => {
                                const searchParams = new URLSearchParams();
                                const updatedTerms = initialTerms?.includes(
                                  c.term_id
                                )
                                  ? initialTerms.filter((t) => t !== c.term_id)
                                  : [...initialTerms, c.term_id];
                                searchParams.append(
                                  "terms",
                                  updatedTerms.join(",")
                                );
                                searchParams.append("sortType", sortType);
                                navigate(
                                  location.pathname +
                                    "?" +
                                    searchParams.toString()
                                );
                              }}
                            />
                            <label htmlFor={`cat-${c.term_id}`}>
                              {decodeHTMLEntities(c.name)} [{subCategoryCount}]
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );

  const mainElement: JSX.Element = (
    <div>
      <h2>{pageCategoryName}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: pageCategory?.description || "" }}
      ></div>
      <div style={{ display: "none" }}>
        <div>Path: {location.pathname}</div>
        <div>Total Count: {filteredProducts.length}</div>
        <div>Initial Terms: {JSON.stringify(initialTerms)}</div>
        <div>
          product-category compare:{" "}
          <a
            href={`/product-category${location.pathname}`}
            target="_blank"
            rel="noreferrer"
          >
            Link
          </a>
        </div>
        <div>Main terms: {pageCategory?.name}</div>
      </div>
      <button
        className={classes.toggleFiltersButton}
        onClick={() => setShowCategoryFilter((prev) => !prev)}
      >
        Open Filters
      </button>
      <div>
        <label>Sort by:</label>
        <select
          value={sortType || "Most Reviews"}
          onChange={(e) => {
            const searchParams = new URLSearchParams();

            searchParams.append("terms", initialTerms.join(","));
            searchParams.append("sortType", e.target.value);
            navigate(location.pathname + "?" + searchParams.toString());
          }}
        >
          {SORT_TYPES.map((t) => (
            <option key={t} value={t}>
              {t}
            </option>
          ))}
        </select>
      </div>
      <Pager page={page} pageCount={pageCount} terms={initialTerms} withTerms />
      <ProductsDisplay slicedProducts={slicedProducts} />
      <Pager page={page} pageCount={pageCount} terms={initialTerms} withTerms />
    </div>
  );

  return (
    <div className={classes.main}>
      {sideBarElement}
      {mainElement}
    </div>
  );
}

const queryClient = new QueryClient();

export const StoreBrowserFinal = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/product-category/">
        <StoreBrowserInner />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

const StoreBrowser = () => {
  return (
    <HashRouter>
      <StoreBrowserInner />
    </HashRouter>
  );
};

export default StoreBrowser;
