import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const libraryDelete: IApiFetcher = async ({
  utoken,
  album_id,
  omitUserAgent = false,
}: {
  utoken: string;
  album_id: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.post<any>(
    `${HOSTNAME}${libraryDelete.url}`,
    { album_id },
    {
      headers,
    }
  );
  return response.data;
};
libraryDelete.url = "/wp-json/wp/v3/library/del";
libraryDelete.method = 'POST';

const LibraryDel: ApiTesterComponent = ({ loginData }: { loginData: ILoginData2 | null }) => {
  const [toRemove, setToRemove] = useState<string>("");

  const onLibraryDel: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!toRemove) return;

    if (loginData?.token) {
      LibraryDel.fetcher({
        utoken: loginData.token,
        album_id: toRemove,
        omitUserAgent: true,
      }).then((data) => {
        console.log("libraryDelete", data);
      });
    }
  };

  return (
    <div>
      <h3>LibraryDel</h3>

      <form onSubmit={onLibraryDel}>
        <input
          type="text"
          value={toRemove}
          required
          onChange={(event) => setToRemove(event.target.value)}
        />
        <input type="submit" value="Remove" />
      </form>
    </div>
  );
};
LibraryDel.fetcher = libraryDelete;

export default LibraryDel;
