import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useRef, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const tracksAlbumsByGenreGet: IApiFetcher = async ({
  genreID,
  utoken,
  filterOn,
  omitUserAgent = false,
}: {
  genreID: string,
  utoken: string;
  filterOn: boolean,
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<any>(`${HOSTNAME}${tracksAlbumsByGenreGet.url}`, {
    headers,
    validateStatus: () => true,
    params: {
      genre_id: genreID,
      filterOn
    }
  });
  return response.data;
};
tracksAlbumsByGenreGet.url = "/wp-json/wp/v3/tracks/albums_by_genre";
tracksAlbumsByGenreGet.method = 'GET';

const TracksAlbumsByGenre: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const genreIDRef = useRef<HTMLInputElement>(null);
  const filterOnRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!loginData?.token) return;
    if(!genreIDRef) return;

    const filterOn = filterOnRef.current?.checked ?? false;

    setIsLoading(true);
    tracksAlbumsByGenreGet({
      genreID: genreIDRef.current?.value,
      utoken: loginData.token,
      omitUserAgent: true,
      filterOn
    }).then((data) => {
      setIsLoading(false);
      setData(JSON.stringify(data, null, 2));
    });
  };

  return (
    <div className="space-y-1">
      <div className="p-2">
        <h3 className="text-xl font-bold my-2">TracksAlbumsByGenre</h3>
        <form onSubmit={onSubmit}>
          <label className="block">
            <span className="inline-block mr-2">Genre ID</span>
            <input className="border px-2 py-1" type="text" ref={genreIDRef} />
          </label>
          <label className="block">
            <span className="inline-block mr-2">Filter On</span>
            <input className="border px-2 py-1" type="checkbox" ref={filterOnRef} />
          </label>
          <div><button className="border px-2 py-1" type="submit">Fetch</button></div>
        </form>
      </div>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

TracksAlbumsByGenre.fetcher = tracksAlbumsByGenreGet;

export default TracksAlbumsByGenre;
