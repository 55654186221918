import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Pager.module.css";

const RANGE = 2;

const Pager = ({
  pageCount,
  page,
  terms,
  style,
  withTerms = false,
  otherParams = {},
}: {
  style?: CSSProperties;
  pageCount: number;
  page: number;
  terms: number[];
  withTerms?: boolean;
  otherParams?: {[key: string]: string}
}) => {
  const navigate = useNavigate();

  if (pageCount <= 1) return null;

  const lowerPage = Math.max(0, page - RANGE);
  const upperPage = Math.min(pageCount - 1, page + RANGE);

  const pages: (number | string)[] = Array.from(
    { length: upperPage - lowerPage + 1 },
    (_, idx) => lowerPage + idx
  );

  if (pageCount - 1 !== upperPage) {
    if (upperPage !== pageCount - 2) {
      pages.push("b");
    }
    pages.push(pageCount - 1);
  }
  if (lowerPage !== 0) {
    if (lowerPage !== 1) {
      pages.unshift("a");
    }
    pages.unshift(0);
  }

  const getParamString = (num: number) => {
    const searchParams = new URLSearchParams();
    searchParams.append("page", num.toString());
    if(withTerms) searchParams.append("terms", terms.map((n) => n.toString()).join(","));
    Object.entries(otherParams).forEach(([key, value]) => {
      searchParams.append(key, value);
    })
    return searchParams.toString();
  };

  return (
    <div className={classes.pager} style={style}>
      <button
        className={classes.nextPrev}
        disabled={page === 0}
        onClick={() =>
          navigate({
            search: `?` + getParamString(page - 1),
          })
        }
      >
        Prev
      </button>
      <div className={classes.numberBlocks}>
        {pages.map((p) =>
          typeof p === "number" ? (
            <button
              className={classes.number}
              key={p}
              disabled={p === page}
              onClick={() =>
                navigate({
                  search: "?" + getParamString(p),
                })
              }
            >
              {p + 1}
            </button>
          ) : (
            <button disabled className={classes.gap} key={p}>
              ...
            </button>
          )
        )}
      </div>
      <button
        className={classes.nextPrev}
        disabled={page === pageCount - 1}
        onClick={() =>
          navigate({
            search: "?" + getParamString(page + 1),
          })
        }
      >
        Next
      </button>
    </div>
  );
};

export default Pager;
