import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useState } from "react";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const fetcher: IApiFetcher = async ({
  data,
  omitUserAgent = false,
}: {
  utoken: string;
  data: { email: string; username?: string; password: string };
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }

  const response = await axios.post<any>(`${HOSTNAME}${fetcher.url}`, data, {
    headers,
  });
  return response.data;
};
fetcher.url = "/wp-json/wp/v3/users/login-apple";
fetcher.method = "POST";

const UsersLoginApple: ApiTesterComponent = () => {
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const formData = {
    };

    setIsLoading(true);
    fetcher({
      data: formData,
      omitUserAgent: true,
    }).then((res) => {
      setIsLoading(false);
      setData(JSON.stringify(res.data));
    }).catch((err) => {
      setIsLoading(false);
      setData(JSON.stringify(err.response.data, null, 2));
    })
  };
  return (
    <div>
      <h3>UsersLoginApple</h3>
      <form onSubmit={onSubmit}>
        <input type="submit" value="Submit" />
      </form>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

UsersLoginApple.fetcher = fetcher;

export default UsersLoginApple;
