import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useRef, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const tracksAlbumInfoGet: IApiFetcher = async ({
  album_id,
  utoken,
  omitUserAgent = false,
}: {
  album_id: string,
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<any>(`${HOSTNAME}${tracksAlbumInfoGet.url}/${album_id}`, {
    headers,
    validateStatus: () => true
  });
  return response.data;
};
tracksAlbumInfoGet.url = "/wp-json/wp/v3/tracks/album_info";
tracksAlbumInfoGet.method = 'GET';

const TracksAlbumInfo: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const albumIDRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!loginData?.token) return;
    if(!albumIDRef) return;

    setIsLoading(true);
    tracksAlbumInfoGet({
      album_id: albumIDRef.current?.value,
      utoken: loginData.token,
      omitUserAgent: true,
    }).then((data) => {
      setIsLoading(false);
      setData(JSON.stringify(data, null, 2));
    });
  };

  return (
    <div>
      <h3>TracksAlbumInfo</h3>
      <form onSubmit={onSubmit}>
        <label>Album ID</label><input type="text" ref={albumIDRef} />
      <button type="submit">Fetch</button>
      </form>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

TracksAlbumInfo.fetcher = tracksAlbumInfoGet;

export default TracksAlbumInfo;
