import axios, { AxiosRequestHeaders } from "axios";
import {
  FormEventHandler,
  useRef,
  useState,
} from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const fetcher: IApiFetcher = async ({
  data,
  omitUserAgent = false,
}: {
  data: { email: string; username?: string; password: string };
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  // headers["utoken"] = utoken;

  const response = await axios.post<any>(`${HOSTNAME}${fetcher.url}`, data, {
    headers,
  });
  return response.data;
};
fetcher.url = "/wp-json/wp/v3/users/register";
fetcher.method = 'POST';

const UsersRegister: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if(!emailInputRef.current || !usernameInputRef.current || !passwordInputRef.current) return;
    const formData = {
      email: emailInputRef.current.value,
      username: usernameInputRef.current.value,
      password: passwordInputRef.current.value,
    }


    setIsLoading(true);
    fetcher({data: formData, omitUserAgent: true}).then(res => {
      setIsLoading(false);
      setData(JSON.stringify(res.data));
    })
  };
  return (
    <div>
      <h3>UsersRegister</h3>
      <form onSubmit={onSubmit}>
        <div>
          <label>Email</label>
          <input required type="email" ref={emailInputRef} />
        </div>
        <div>
          <label>Username</label>
          <input type="text" ref={usernameInputRef} />
        </div>
        <div>
          <label>Password</label>
          <input required type="password" ref={passwordInputRef} />
        </div>
        <input type="submit" value="Register" />
      </form>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

UsersRegister.fetcher = fetcher;

export default UsersRegister;
