import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const usersHomePost: IApiFetcher = async ({
  utoken,
  omitUserAgent = false,
}: {
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.post<any>(
    `${HOSTNAME}${usersHomePost.url}`,
    null,
    {
      headers,
    }
  );
  return response.data;
};
usersHomePost.url = "/wp-json/wp/v3/users/home";
usersHomePost.method = 'POST';

const UsersHome: ApiTesterComponent = ({ loginData }: { loginData: ILoginData2 | null }) => {
  const [data, setData] = useState<any>(null)
  const [loading, setIsLoading] = useState<boolean>(false);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (loginData?.token) {
      setIsLoading(true);
       UsersHome.fetcher({
        utoken: loginData.token,
        omitUserAgent: true,
      }).then(data => {
        setData(data);
        setIsLoading(false);
      });
    }
  };

  return (
    <div>
      <h3>UsersHome</h3>
      <form onSubmit={onSubmit}>
        <input type="submit" value="Fetch" />
      </form>
      <pre>{loading ? "Loading..." : JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

UsersHome.fetcher = usersHomePost;

export default UsersHome;
