import { useMutation } from "@tanstack/react-query";
import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import { FormEventHandler, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

type Ops = "CHECK" | "DELETE";

interface IAPIFunctionConfig {
  op: Ops;
  utoken: string;
  omitUserAgent?: boolean;
}

export const userDelete: IApiFetcher = async ({
  op,
  utoken,
  omitUserAgent = false,
}: IAPIFunctionConfig) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.post<
    any,
    AxiosResponse<any>,
    { op: Ops } | null
  >(
    `${HOSTNAME}${userDelete.url}`,
    { op: op },
    {
      headers,
    }
  );
  return response.data;
};
userDelete.url = "/wp-json/wp/v3/users/account_deletion";
userDelete.method = "POST";

const UsersAccountDeletion: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const [op, setOp] = useState<boolean>(false);
  const { isLoading, data, mutate } = useMutation(
    (config: IAPIFunctionConfig) => userDelete(config)
  );
  const onDeleteAccount: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!loginData?.token) return;

    mutate({ op: op ? 'DELETE' : 'CHECK', utoken: loginData.token, omitUserAgent: true });
  };

  return (
    <div>
      <h3>UsersAccountDeletion</h3>

      <form onSubmit={onDeleteAccount}>
        <label>Perform Delete</label>
        <input
          type="checkbox"
          checked={op}
          onChange={(e) => setOp(e.target.checked)}
        />
        <input type="submit" value="Delete Account" />
      </form>

      <pre>{isLoading ? "Loading..." : JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};
UsersAccountDeletion.fetcher = userDelete;

export default UsersAccountDeletion;
