import { FormEventHandler, useEffect, useRef, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import classes from "./SearchApp.module.scss";
import Fuse from "fuse.js";
import he from "he";
import Pager from "./Pager";
import { ISearchResultPlus } from "./models";
import { useAllItemsForSearch } from "./utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export function romanize(num: number) {
  const lookup: { [key: string]: number } = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let roman = "";
  for (let i in lookup) {
    while (num >= lookup[i]) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

const replaceWithRomanNumerals = (s: string) => {
  return s.replace(/((experience|wave|onda)s?) ([1-9][0-9]*)/, (_1, _2, m3, m4) => {
    return `${m3} ${romanize(m4).toLowerCase()}`;
  });
};

const queryClient = new QueryClient();

export const SearchAppInner = ({ base = "" }: { base?: string }) => {
  const { data } = useAllItemsForSearch();
  const { term } = useParams();
  const searchTermRef = useRef<HTMLInputElement>(null);
  const [debug, setDebug] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const locationParams = new URLSearchParams(location.search);

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      const tagName = document.activeElement?.tagName.toLowerCase();
      if (tagName === "input" || tagName === "textarea") return;

      if (event.key === "?") {
        setDebug((prev) => !prev);
      }
    };

    document.addEventListener("keypress", onKeyPress);
    return () => {
      document.removeEventListener("keypress", onKeyPress);
    };
  }, []);
  const decodedTerm = replaceWithRomanNumerals(
    decodeURIComponent((term || "").replace(/\+/g, "%20"))
      .toLowerCase()
      .trim()
  ).replace(/^(gateway experience) ([ivx]+)/, '$1 wave $2');


  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!searchTermRef.current) return;
    navigate(`${base}/${searchTermRef.current.value}`);
  };

  const allItems: ISearchResultPlus[] = ( data || []).map(
    (e) => ({
      ...e,
      titleForSearch: e.title
        .replace(/®/g, "")
        .replace(/&#8211;/g, "-")
        .toLowerCase(),
    })
  );

  const fuseObj = new Fuse(allItems, {
    includeScore: true,
    location: 0,
    keys: ["titleForSearch"],
  });

  const results = term ? fuseObj.search(term) : [];

  const exactMatch = new RegExp(`^${decodedTerm}$`);
  const exactStarting = new RegExp(`^${decodedTerm}\\b`);
  const exactSomewhere = new RegExp(`\\b${decodedTerm}\\b`);
  results.forEach((el) => {
    if (!el.score) return;
    if (exactMatch.test(el.item.titleForSearch)) el.score -= 3;
    else if (exactStarting.test(el.item.titleForSearch)) el.score -= 2;
    else if (exactSomewhere.test(el.item.titleForSearch)) el.score -= 1;
  });

  results.sort((a, b) => {
    if (!a.score || !b.score) return 0;
    return a.score - b.score;
  });

  const pageCount = Math.ceil(results.length / 10);
  const pageParam = locationParams.get("page");
  const pageNumber = pageParam !== null ? +pageParam : 0;

  return (
    <div className={classes.searchApp}>
      <section className="single-post-breadcrumbs breadcrumbs-v5 fg-force-fullwidth header-with-titlebar">
        <div className="fg-bg">
          <div className="fg-bg-layer fg-bg-type-color"></div>
        </div>
        <section className="breadcrumbs-v4">
          <h2 className="breadcrumbs-v4-title">{decodedTerm}</h2>
        </section>
      </section>
      <form onSubmit={onSubmit}>
        <div style={{ width: "100%" }}>
          <h2 style={{ textAlign: "center" }}>Search</h2>
          {debug && <p>{decodedTerm}</p>}
          <div role="search" id="searchform" className="searchform input-group">
            <input
              name="s"
              id="s"
              type="text"
              placeholder="Search …"
              className="form-control"
              defaultValue={decodedTerm}
              ref={searchTermRef}
              spellCheck={false}
              required
            />
            <span className="input-group-btn">
              <button type="submit" className="btn" id="searchsubmit">
                <i className="ff-font-awesome4 icon-search"></i>
              </button>
            </span>
          </div>
        </div>
      </form>

      <Pager
        pageCount={pageCount}
        page={pageNumber}
        terms={[]}
        style={{ margin: "24px 0" }}
      />
      <div className={classes.results}>
        {results.slice(pageNumber * 10, (pageNumber + 1) * 10).map((r) => (
          <div key={r.item.ID} className={classes.resultItem}>
            <a href={r.item.permalink}>
              <img src={r.item.thumbnail_url} alt="" />
            </a>
            <div>
              {debug && (
                <div className={classes.debug}>
                  {r.item.ID} : {r.item.title} : {r.item.titleForSearch} : {r.score}
                </div>
              )}
              <h2>
                <a href={r.item.permalink}>{he.decode(r.item.title)}</a>
              </h2>
              <p
                className={classes.excerpt}
                dangerouslySetInnerHTML={{ __html: r.item.excerpt }}
              ></p>
              <section>{r.item.date}</section>
            </div>
          </div>
        ))}
      </div>
      <Pager
        pageCount={pageCount}
        page={pageNumber}
        terms={[]}
        style={{ margin: "24px 0" }}
      />
    </div>
  );
};

const SearchApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/search/">
        <Routes>
          <Route path=":term" element={<SearchAppInner />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default SearchApp;
