import axios, { AxiosRequestHeaders } from "axios";
import { useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const playlistsGetShared: IApiFetcher = async ({
  utoken,
  pl_id,
  omitUserAgent = false,
}: {
  utoken: string;
  pl_id: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<any>(
    `${HOSTNAME}${playlistsGetShared.url}`,
    {
      headers,
      params: {
        pl_id,
      },
    }
  );
  return response.data;
};
playlistsGetShared.url = "/wp-json/wp/v3/playlists/getshared";
playlistsGetShared.method = 'GET';

const PlaylistsGetshared: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const [sharedPlaylist, setSharedPlaylist] = useState<string>("");

  // useEffect(() => {
  //   if (loginData?.token) {
  //     onGetSharedPlaylist(loginData);
  //   }
  // }, [loginData]);

  const onGetSharedPlaylist = async (loginData: ILoginData2 | null) => {
    if (!loginData?.token) return;
    setSharedPlaylist("");
    await PlaylistsGetshared.fetcher({
      pl_id: "2521",
      utoken: loginData.token,
      omitUserAgent: true,
    }).then((response) => {
      setSharedPlaylist(JSON.stringify(response.data, null, 2));
    });
  };

  return (
    <div>
      <h3>Get Shared Playlist</h3>

      <button onClick={() => onGetSharedPlaylist(loginData)}>Do</button>
      <pre>{sharedPlaylist}</pre>
    </div>
  );
};
PlaylistsGetshared.fetcher = playlistsGetShared;

export default PlaylistsGetshared;
