import { IProductInfo } from "../../models";
import StarRating from "./StarRating";
import classes from "./StoreBrowser.module.scss";

const ProductsDisplay = ({
  slicedProducts,
}: {
  slicedProducts: IProductInfo[];
}) => {
  const products = [...slicedProducts];
  return (
    <div className={classes.productList}>
      {products.map((product) => {
        const originalPrice =
          product.price !== product.regular_price ? (
            <span className={classes.regularPrice}>
              ${product.regular_price}
            </span>
          ) : null;
        return (
          <a
            href={product.permalink}
            className={classes.productItem}
            key={product.ID}
          >
            <img
              className={classes.productImage}
              src={product.thumbnail_url}
              alt=""
            />
            <div className={classes.productDetails}>
              <div className={classes.productTitle}>{product.post_title}</div>
              {product.average_rating !== 0 ? (
                <div className={classes.productRating}>
                  <StarRating rating={product.average_rating} />
                </div>
              ) : null}
              <div className={classes.productPrice}>
                {product.product_type} {originalPrice} ${product.price}
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default ProductsDisplay;
