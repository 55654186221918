import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from "axios";
import { FormEventHandler, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const fetcher: IApiFetcher = async ({
  utoken,
  data,
  omitUserAgent = false,
}: {
  utoken: string;
  data: { email: string; username?: string; password: string };
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios
    .post<any>(`${HOSTNAME}${fetcher.url}`, data, {
      headers,
    })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err: AxiosError) => {
      return err.response;
    });
  return response;
};
fetcher.url = "/wp-json/wp/v3/users/googlepay_validate";
fetcher.method = "POST";

const UsersGooglepayValidate: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const [data, setData] = useState<string>("");
  const [packageName, setPackageName] = useState<string>("");
  const [purchaseType, setPurchaseType] = useState<string>("");
  const [purchaseToken, setPurchaseToken] = useState<string>("");

  const [loading, setIsLoading] = useState<boolean>(false);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const formData = {
      package_name: packageName,
      purchase_type: purchaseType,
      purchase_token: purchaseToken
    }

    setIsLoading(true);
    fetcher({
      data: formData,
      omitUserAgent: true,
      utoken: loginData?.token,
    }).then((res) => {
      setIsLoading(false);
      setData(JSON.stringify(res.data));
    });
  };
  return (
    <div>
      <h3>UsersGooglepayValidate</h3>
      <form onSubmit={onSubmit}>
        <div>
          <label>Package Name</label>
          <input
            type="text"
            required
            value={packageName}
            onChange={(e) => setPackageName(e.target.value)}
          />
        </div>
        <div>
          <label>Purchase Type </label>
          <input
            type="text"
            required
            value={purchaseType}
            onChange={(e) => setPurchaseType(e.target.value)}
          />
        </div>
        <div>
          <label>Purchase Token</label>
          <input
            type="text"
            required
            value={purchaseToken}
            onChange={(e) => setPurchaseToken(e.target.value)}
          />
        </div>

        <input type="submit" value="Submit" />
      </form>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

UsersGooglepayValidate.fetcher = fetcher;

export default UsersGooglepayValidate;
