import axios, { AxiosRequestHeaders } from "axios";
import { useState, useEffect } from "react";
import { ILoginData2, ILibraryGetPlaylist, ILibraryGetResponse } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";
import classes from '../ApiTesterApp.module.scss';

export const libraryGet: IApiFetcher = async ({
  utoken,
  omitUserAgent = false,
}: {
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<ILibraryGetResponse>(
    `${HOSTNAME}${libraryGet.url}`,
    {
      headers,
    }
  );
  return response.data;
};
libraryGet.url = "/wp-json/wp/v3/library/get";
libraryGet.method = 'GET';

const LibraryGet: ApiTesterComponent = ({ loginData }: { loginData: ILoginData2 | null }) => {
  const [playlistData, setPlaylistData] = useState<string>("");
  const [playlists, setPlaylists] = useState<ILibraryGetPlaylist[] | null>(
    null
  );

  const onGetPlaylist = (loginData: ILoginData2 | null) => {
    if (loginData?.token) {
      setPlaylistData("");
      setPlaylists([]);

      LibraryGet.fetcher({ utoken: loginData.token, omitUserAgent: true }).then(
        (response) => {
          setPlaylistData(JSON.stringify(response, null, 2));
          setPlaylists(response.data);
        }
      );
    }
  };

  useEffect(() => {
    let isActive = true;
    if (loginData?.token) {
      if (isActive) onGetPlaylist(loginData);
    }
    return () => {
      isActive = false;
    };
  }, [loginData]);

  return (
    <div>
      <h3>LibraryGet</h3>
      <p>
        <button onClick={() => onGetPlaylist(loginData)}>Fetch Playlist</button>
      </p>
      {playlists ? (
        <div>
          <p>Playlists Count: {playlists.length}</p>
          {playlists.map((playlist) => (
            <div key={playlist.pl_id}>
              <div>
                <div>
                  {playlist.pl_id}: {playlist.name || "Unnamed"} (Items Count:{" "}
                  {playlist.items.length})
                </div>
                <div className={[classes.albumsList].join("")}>
                  {playlist.items.map((album, index) => {
                    return (
                      <div key={`${album.album_id}-${index}`}>
                        {index + 1}. [{album.album_id}] {album.album_name})
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <pre>{playlistData}</pre>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Unfetched</p>
      )}
    </div>
  );
};
LibraryGet.fetcher = libraryGet;

export default LibraryGet;