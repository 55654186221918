import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useRef, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const usersProfileMetaGet: IApiFetcher = async ({
  metaKey,
  utoken,
  omitUserAgent = false,
}: {
  metaKey: string;
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<any>(
    `${HOSTNAME}${usersProfileMetaGet.url}/${metaKey}`,
    {
      headers,
    }
  );
  return response.data;
};
usersProfileMetaGet.url = "/wp-json/wp/v3/users/profile/meta";
usersProfileMetaGet.method = "GET";

const UsersProfileMeta: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const metaKeyRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onFetch: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    
    if (!loginData?.token) return;
    if (!metaKeyRef.current?.value) return;
    setIsLoading(true);
    usersProfileMetaGet({
      metaKey: metaKeyRef.current.value,
      utoken: loginData.token,
      omitUserAgent: true,
    }).then((data) => {
      setIsLoading(false);
      setData(JSON.stringify(data, null, 2));
    });
  };

  return (
    <div>
      <h3>UsersProfileMeta</h3>

      <form onSubmit={onFetch}>
        <label>Meta Key:</label>
        <input type="text" ref={metaKeyRef} />
        <button type="submit">Fetch</button>
      </form>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

UsersProfileMeta.fetcher = usersProfileMetaGet;

export default UsersProfileMeta;
