import { MouseEventHandler, useEffect, useState } from "react";
import classes from "./ApplyCategoryApp.module.css";

const bobz: {nonce: string, nonce_rest: string} = (window as any).bobz;

interface IProduct {
  ID: string;
  post_title: string;
  categories: string[];
}

const fetchProductList = async () => {
  return fetch("/wp-json/special-tools/product-list")
    .then((res) => res.json())
    .then((data: IProduct[]) => data);
};

const ApplyCategoryApp = () => {
  const [selected, setSelected] = useState<string[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    fetchProductList().then((fetchedProducts) => {
      setProducts(fetchedProducts);
    });
  }, []);

  useEffect(() => {
    setSelected(
      products
        .filter(
          (p) =>
            p.categories.filter((c) =>
              /(english|accessories|chinese-mandarin|french-francais|uncategorized|german-deutsch|hungarian-magyar|italian|greek|polish-polski|romana|spanish-espanol|japanese)/.test(
                c
              )
            ).length === 0
        )
        .map((p) => p.ID)
    );
  }, [products]);

  const onCheckHandler = (productId: string) => {
    setSelected((current) => {
      return current.indexOf(productId) !== -1
        ? current.filter((id) => id !== productId)
        : [...current, productId];
    });
  };

  const onApplyEnglish: MouseEventHandler<HTMLButtonElement> = (event) => {
    setError("");
    fetch("/wp-json/special-tools/apply-category", {
      method: "POST",
      headers: { "Content-Type": "application/json", "X-WP-Nonce": bobz.nonce_rest },
      body: JSON.stringify({ action: "add", selected, nonce: bobz.nonce }),
    }).then((res) =>
      res.json().then((data) => {
        if (data.message) {
          setError(data.message);
        } else {
          fetchProductList().then((fetchedProducts) => {
            setProducts(fetchedProducts);
          });
        }
      })
    );
  };

  return (
    <div>
      <h2>Apply Category App!</h2>
      {!!selected.length && (
        <div>
          {selected.length} Selected: {selected.join(", ")}
        </div>
      )}
      <button onClick={onApplyEnglish}>Apply English to Products</button>
      {!!error && <div style={{ color: "red" }}>{error}</div>}
      <div>
        {products.map((product) => (
          <div key={product.ID} className={classes.productItem}>
            <div>
              <input
                onChange={() => onCheckHandler(product.ID)}
                type="checkbox"
                id={`checkbox-${product.ID}`}
                checked={selected.indexOf(product.ID) !== -1}
              />
              <label htmlFor={`checkbox-${product.ID}`}>{product.ID}</label>
            </div>
            <div>{product.post_title}</div>
            <div>{product.categories.join(", ")}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplyCategoryApp;
