import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useRef, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const fetcher: IApiFetcher = async ({
  utoken,
  data,
  omitUserAgent = false,
}: {
  utoken: string;
  data: { email: string; username?: string; password: string };
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  const $userAgent = "I:2.5.0(256)";
  if (!omitUserAgent) {
    headers["User-Agent"] = $userAgent;
  }
  headers["X-User-Agent-Alt"] = $userAgent
  
  headers["utoken"] = utoken;

  const response = await axios.post<any>(`${HOSTNAME}${fetcher.url}`, data, {
    headers,
    validateStatus: () => true,
  });
  return response.data;
};
fetcher.url = "/wp-json/wp/v3/users/applepay_validate";
fetcher.method = "POST";

const UsersApplepayValidate: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const receiptTextAreaRef = useRef<HTMLTextAreaElement>(null);

  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!receiptTextAreaRef.current) return;
    const formData = {
      receipt: receiptTextAreaRef.current.value,
    };

    setIsLoading(true);
    fetcher({
      data: formData,
      omitUserAgent: true,
      utoken: loginData?.token,
    }).then((res) => {
      setIsLoading(false);
      setData(JSON.stringify(res.data));
    });
  };
  return (
    <div>
      <h3>UsersApplepayValidate</h3>
      <form onSubmit={onSubmit}>
        <div>
          <label>Receipt</label>
          <textarea
            spellCheck={false}
            ref={receiptTextAreaRef}
            rows={10}
            style={{ width: "100%" }}
          />
        </div>

        <input type="submit" value="Submit" />
      </form>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

UsersApplepayValidate.fetcher = fetcher;

export default UsersApplepayValidate;
