import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ICategory, ISearchResult } from "./models";

const HOSTNAME = process.env.app_url || "";

export function setWithExpiry<T>(key: string, value: T, ttl: number) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry<T>(key: string): T | null {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export const getAllItemsForSearch = async () => {
  const ALL_ITEMS_FOR_SEARCH = "ALL_ITEMS_FOR_SEARCH";

  const cachedData = getWithExpiry<ISearchResult[]>(ALL_ITEMS_FOR_SEARCH);
  if (cachedData) return cachedData;

  const response = await axios.get<ISearchResult[]>(
    `${HOSTNAME}/wp-json/hemi/v1/all-items-for-search`
  );

  setWithExpiry(ALL_ITEMS_FOR_SEARCH, response.data, 60 * 60 * 1000);

  return response.data;
};

export const useAllItemsForSearch = () => {
  const { data, isLoading } = useQuery(
    ["all-items-for-search"],
    getAllItemsForSearch,
    { networkMode: "always" }
  );

  return { data, isLoading };
};

export const getSubcategories = (
  cats: ICategory[],
  allCategories: ICategory[]
): ICategory[] => {
  if (cats.length === 0) return [];
  const parentIds = cats.map((c) => c.term_id);
  const subCategories = allCategories.filter((c) =>
    parentIds.some((id) => c.parent === id)
  );
  return [...cats, ...getSubcategories(subCategories, allCategories)];
};