import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Link, Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import ApiTesterApp from "./components/ApiTester/ApiTesterApp";
import ApplyCategoryApp from "./ApplyCategoryApp";
import ContentLayout from "./ContentLayout";
import { SearchAppInner } from "./SearchApp";
import classes from "./SpecialToolsApp.module.css";
import { StoreBrowserInner } from "./components/StoreBrowser/StoreBrowser";
import { SubscriptionAdminAppInner } from "./SubscriptionAdminApp";

const queryClient = new QueryClient();

const SpecialToolsApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/special-tools/">
        <ContentLayout>
          <div className={classes.root}>
            <h1>Special Tools!</h1>
            <nav>
              <Link to="/">Home</Link>
              <Link to="/apply-category-app">Apply Category App</Link>
              <Link to="/api-tester-app">API Tester App</Link>
              <Link to="/search-app">Search App</Link>
              <Link to="/store-browser">Store Browser App</Link>
              <Link to="/subscription-admin">Subscription Admin</Link>
            </nav>
            <Routes>
              <Route
                path="/apply-category-app"
                element={<ApplyCategoryApp />}
              />
              <Route path="/api-tester-app/*" element={<ApiTesterApp base="/api-tester-app" />} />
              <Route
                path="/search-app/:term"
                element={<SearchAppInner base="/search-app" />}
              />
              <Route
                path="/search-app"
                element={<SearchAppInner base="/search-app" />}
              />
              <Route
                path="/subscription-admin"
                element={<SubscriptionAdminAppInner base="/subscription-admin" />}
              />
              <Route
                path="/store-browser/*"
                element={<StoreBrowserInner base="/store-browser" />}
              />
              <Route
                path="/"
                element={
                  <div>
                    <h2>Home</h2>
                    <p>Welcome!</p>
                  </div>
                }
              />
            </Routes>
          </div>
        </ContentLayout>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default SpecialToolsApp;
