import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useCallback, useEffect, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";
import { tracksGetLangsGet } from "./TracksGetLangs";

export const tracksSetLangPost: IApiFetcher = async ({
  utoken,
  data,
  omitUserAgent = false,
}: {
  utoken: string;
  data: any;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.post<any>(
    `${HOSTNAME}${tracksSetLangPost.url}`,
    { lang_id: data },
    {
      headers,
    }
  );
  return response.data;
};
tracksSetLangPost.url = "/wp-json/wp/v3/tracks/set_lang";
tracksSetLangPost.method = 'POST';

const TracksSetLang: ApiTesterComponent = ({ loginData }: { loginData: ILoginData2 | null }) => {
  const [langId, setLangId] = useState<string>("");
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(true);

  const getLangs = useCallback(() => loginData ? tracksGetLangsGet({utoken: loginData.token, omitUserAgent: true}).then(data => {
    setData(JSON.stringify(data, null, 2));
    setIsLoading(false);
  }) : undefined, [loginData]);

  useEffect(() => {
    getLangs();
  }, [getLangs])

  const onSubmitForm: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (loginData?.token) {
      setIsLoading(true);
       TracksSetLang.fetcher({
        utoken: loginData.token,
        data: langId,
        omitUserAgent: true,
      }).then(() => {
        getLangs();
      });
    }
  };

  return (
    <div>
      <h3>TracksSetLang</h3>
      <form onSubmit={onSubmitForm}>
        <input
          type="text"
          value={langId}
          required
          onChange={(event) => setLangId(event.target.value)}
        />
        <input type="submit" value="Set" />
      </form>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

TracksSetLang.fetcher = tracksSetLangPost;

export default TracksSetLang;
