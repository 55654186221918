import axios, { AxiosRequestHeaders } from "axios";
import { ILoginData, ILoginSuccess } from "../../models";

export const HOSTNAME = process.env.app_url || "";
const BYPASS_TOKEN = window.localStorage.getItem('bypass-token') || '';

export const login = async (
  { email, password }: ILoginData,
  bypassPasswordCheck: boolean = false
) => {
  const data = { email, password };
  const headers: AxiosRequestHeaders = {};

  if (bypassPasswordCheck) {
    headers["X-Bypass-Password-Check"] = BYPASS_TOKEN;
  }

  const response = await axios.post<ILoginSuccess>(
    `${HOSTNAME}/wp-json/wp/v3/users/login`,
    data,
    {
      headers,
    }
  );

  return response;
};
