import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useCallback, useEffect, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";
import { artistsSubscriptionGet } from "./ArtistsSubscriptionGet";

export const artistsSubscriptionDel: IApiFetcher = async ({
  utoken,
  artist_id,
  omitUserAgent = false,
}: {
  utoken: string;
  artist_id: number;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.post<any>(
    `${HOSTNAME}${artistsSubscriptionDel.url}`,
    { artist_id },
    {
      headers,
    }
  );
  return response.data;
};
artistsSubscriptionDel.url = "/wp-json/wp/v3/artists/subscription/del";
artistsSubscriptionDel.method = 'POST';

const ArtistsSubscriptionDel: ApiTesterComponent = ({ loginData }: { loginData: ILoginData2 | null }) => {
  const [toAdd, setToAdd] = useState<string>("");
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(true);

  const libraryGetBinded = useCallback(() => loginData ? artistsSubscriptionGet({utoken: loginData.token, omitUserAgent: true}).then(data => {
    setData(JSON.stringify(data, null, 2));
    setIsLoading(false);
  }) : undefined, [loginData]);

  useEffect(() => {
    libraryGetBinded();
  }, [libraryGetBinded])

  const onAddToPlaylist: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (loginData?.token) {
      setIsLoading(true);
       artistsSubscriptionDel({
        utoken: loginData.token,
        artist_id: +toAdd,
        omitUserAgent: true,
      }).then(() => {
        libraryGetBinded();
      });
    }
  };

  return (
    <div>
      <h3>ArtistsSubscriptionDel</h3>
      <form onSubmit={onAddToPlaylist}>
        <input
          type="text"
          value={toAdd}
          required
          onChange={(event) => setToAdd(event.target.value)}
        />
        <input type="submit" value="Remove" />
      </form>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

ArtistsSubscriptionDel.fetcher = artistsSubscriptionDel;

export default ArtistsSubscriptionDel;
