import classes from "./StarRating.module.css";

const StarRating = ({ rating }: { rating: number }) => {
  const width = Math.round((rating / 5) * 100);
  const ratingToFixed = rating.toFixed(2);

  return (
    <div
      className={classes.root}
      role="img"
      aria-label={`Rated ${ratingToFixed} out of 5`}
    >
      <span style={{ width: `${width}%` }}>
        Rated {ratingToFixed} out of 5
      </span>
    </div>
  );
};

export default StarRating;
