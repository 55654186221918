import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useCallback, useEffect, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";
import { libraryGet } from "./LibraryGet";

export const libraryAdd: IApiFetcher = async ({
  utoken,
  data,
  omitUserAgent = false,
}: {
  utoken: string;
  data: any;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const parsedData = JSON.parse(data);

  const response = await axios.post<any>(
    `${HOSTNAME}${libraryAdd.url}`,
    { items: [parsedData] },
    {
      headers,
    }
  );
  return response.data;
};
libraryAdd.url = "/wp-json/wp/v3/library/add";
libraryAdd.method = 'POST';

const LibraryAdd: ApiTesterComponent = ({ loginData }: { loginData: ILoginData2 | null }) => {
  const [toAdd, setToAdd] = useState<string>("");
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(true);

  const libraryGetBinded = useCallback(() => loginData ? libraryGet({utoken: loginData.token, omitUserAgent: true}).then(data => {
    setData(JSON.stringify(data, null, 2));
    setIsLoading(false);
  }) : undefined, [loginData]);

  useEffect(() => {
    libraryGetBinded();
  }, [libraryGetBinded])

  const onAddToPlaylist: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (loginData?.token) {
      setIsLoading(true);
       LibraryAdd.fetcher({
        utoken: loginData.token,
        data: toAdd,
        omitUserAgent: true,
      }).then(() => {
        libraryGetBinded();
      });
    }
  };

  return (
    <div>
      <h3>LibraryAdd</h3>
      <form onSubmit={onAddToPlaylist}>
        <input
          type="text"
          value={toAdd}
          required
          onChange={(event) => setToAdd(event.target.value)}
        />
        <input type="submit" value="Add" />
      </form>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

LibraryAdd.fetcher = libraryAdd;

export default LibraryAdd;
