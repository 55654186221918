import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import classes from "./SubscriptionAdminApp.module.scss";
import Pager from "./Pager";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { useEffect, useRef } from "react";

const queryClient = new QueryClient();

const bobz: { nonce: string; nonce_rest: string } = (window as any).bobz;

interface UserSubInfo {
  [key: string]: {
    hasWebsiteSub?: boolean;
    hasAppleHsSub?: boolean;
    hasAppleBcSub?: boolean;
    user_login: string;
    user_email: string;
    display_name: string;
    mobileSub?: string;
  };
}

interface FetchedSubscriptionList {
  count: number;
  users: UserSubInfo;
}

const fetcher = () =>
  fetch("/wp-json/hemi/v1/subscriptions-list", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-WP-Nonce": bobz.nonce_rest,
    },
  }).then(
    (res) =>
      res.json() as Promise<FetchedSubscriptionList>
  );

const fetcherWithCache = async () => {
  const cached = localStorage.getItem("adminSubscriptionsList");
  if(cached) {
    return JSON.parse(cached) as Promise<FetchedSubscriptionList>
  }
  const data = await fetcher();
  localStorage.setItem("adminSubscriptionsList", JSON.stringify(data));
  return data;
}

interface Details {
  result: {
    [key: string]: {
      id: number;
      parent_id: number;
      status: string;
      date_created: number;
    }[];
  };
}

const fetchDetails = async (uids: string[]) => {
  if (!uids.length) return Promise.resolve({ result: {} } as Details);
  return fetch(
    `/wp-json/hemi/v1/subscriptions-details?uids=${uids.join(",")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-WP-Nonce": bobz.nonce_rest,
      },
    }
  ).then((res) => res.json() as Promise<Details>);
};

const PER_PAGE = 40;

export const SubscriptionAdminAppInner = ({ base = "" }: { base?: string }) => {
  const { data, isLoading, isError, refetch: refetchList } = useQuery(["tbd"], fetcherWithCache, {
    networkMode: "always",
  });
  const queryRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const location = useLocation();

  const locationParams = new URLSearchParams(location.search);

  const usersMap = data ? data.users : {};
  const users = Object.entries(usersMap).map(([key, value]) => {
    return { uid: key, ...value };
  });

  const pageParam = locationParams.get("page");
  const query = locationParams.get("q");

  const filteredUsers = users.filter((u) => {
    if (!query) return true;
    return new RegExp(query).test(u.user_email);
  });

  const pageCount = Math.ceil(filteredUsers.length / PER_PAGE);
  const pageNumber = pageParam !== null ? +pageParam : 0;

  const displayedUsers = filteredUsers.slice(
    pageNumber * PER_PAGE,
    (pageNumber + 1) * PER_PAGE
  );
  const uids = displayedUsers.map((u) => u.uid);

  const {
    data: detailsData,
    isLoading: detailsIsLoading,
    isError: detailsIsError,
    refetch,
  } = useQuery(["data", uids], () => fetchDetails(uids));

  useEffect(() => {
    if (uids.length) {
      refetch();
    }
  }, [uids, refetch]);
  if (isLoading || detailsIsLoading) return <p>Loading...</p>;
  if (isError || detailsIsError) return <p>Error!</p>;

  const onSearch = () => {
    const q = queryRef.current?.value;

    if (!q) return;

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("q", q);
    navigate(`?${urlSearchParams.toString()}`, { replace: true });
  };

  const onClearCache = () => {
    localStorage.removeItem("adminSubscriptionsList");
    refetchList();
  }

  return (
    <div>
      <div>
        <input type="text" ref={queryRef} />
        <button onClick={onSearch}>Search</button>
        <button onClick={onClearCache}>Clear Cache</button>
      </div>
      <Pager
        pageCount={pageCount}
        page={pageNumber}
        terms={[]}
        style={{ margin: "24px 0" }}
        otherParams={{q: query || ""}}
      />
      <div className={classes.results}>
        {displayedUsers.map((user) => (
          <div key={user.uid}>
            <div className={classes.resultItem}>
              <div>{user.uid}</div>
              <div>
                <div>{user.user_login}</div>
                <div>{user.user_email}</div>
                <div>{user.display_name}</div>
              </div>
              <div>{user.mobileSub || "null"}</div>
              <div>{user.hasWebsiteSub ? 'true' : 'false'}</div>
              <div>{user.hasAppleHsSub ? 'true' : 'false'}</div>
              <div>{user.hasAppleBcSub ? 'true' : 'false'}</div>
              <pre>{JSON.stringify(
                detailsData?.result[user.uid],
                null,
                2
              )}</pre>
            </div>
            {/* <pre>
              {JSON.stringify(
                { user, details: detailsData?.result[user.uid] },
                null,
                2
              )}
            </pre> */}
          </div>
        ))}
      </div>
      <Pager
        pageCount={pageCount}
        page={pageNumber}
        terms={[]}
        style={{ margin: "24px 0" }}
        otherParams={{q: query || ""}}
      />
    </div>
  );
};

const SubscriptionAdminApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/subscription-admin/">
        <Routes>
          <Route path=":term" element={<SubscriptionAdminAppInner />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default SubscriptionAdminApp;
