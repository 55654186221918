import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useCallback, useEffect, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";
import { usersActiveSubscriptionGet } from "./UsersActiveSubscription";

export const usersUpdateSubscription: IApiFetcher = async ({
  utoken,
  data,
  omitUserAgent = false,
}: {
  utoken: string;
  data: any;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.post<any>(
    `${HOSTNAME}${usersUpdateSubscription.url}`,
    data,
    {
      headers,
      validateStatus: () => true,
    }
  );
  return response.data;
};
usersUpdateSubscription.url = "/wp-json/wp/v3/users/update_subscription";
usersUpdateSubscription.method = 'POST';

const UsersUpdateSubscription: ApiTesterComponent = ({ loginData }: { loginData: ILoginData2 | null }) => {
  const [toAdd, setToAdd] = useState<string>("");
  const [res, setRes] = useState<string>("");
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(true);

  const fetchActiveSubscription = useCallback(() => loginData ? usersActiveSubscriptionGet({utoken: loginData.token, omitUserAgent: true}).then(data => {
    setData(JSON.stringify(data, null, 2));
    setIsLoading(false);
  }) : undefined, [loginData]);

  useEffect(() => {
    fetchActiveSubscription();
  }, [fetchActiveSubscription])

  const onUpdateSubscription: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (loginData?.token) {
      setIsLoading(true);
       UsersUpdateSubscription.fetcher({
        utoken: loginData.token,
        data: {
          subscription: toAdd,
          active: true,
        },
        omitUserAgent: true,
      }).then((data) => {
        setRes(JSON.stringify(data, null, 2));
        fetchActiveSubscription();
      });
    }
  };

  const onRefetch = () => {
    fetchActiveSubscription();
  }

  return (
    <div>
      <h3>UsersUpdateSubscription</h3>
      <form onSubmit={onUpdateSubscription}>
        <input
          type="text"
          value={toAdd}
          required
          onChange={(event) => setToAdd(event.target.value)}
        />
        <input type="submit" value="Update" />
      </form>

      <button onClick={onRefetch}>Refetch</button>
      <pre>{res}</pre>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

UsersUpdateSubscription.fetcher = usersUpdateSubscription;

export default UsersUpdateSubscription;
