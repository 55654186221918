import { Link } from "react-router-dom";

const LINKS = [
  {
    name: "New Releases",
    href: "/new-releases/",
  },
  {
    name: "Best Sellers",
    href: "/best-sellers/",
  },
  {
    name: "Accessories",
    href: "/accessories/",
  },
  {
    name: "– T-Shirts",
    href: "/accessories/tshirts/",
  },
  {
    name: "On Sale",
    href: "/on-sale/",
  },
  {
    name: "Meditation",
    href: "/by-real-life-applications/meditation-spiritual-growth/",
  },
  {
    name: "Wellness",
    href: "/by-real-life-applications/wellness/",
  },
  {
    name: "Enhanced Well-Being",
    href: "/by-real-life-applications/enhanced-well-being/",
  },
  {
    name: "Reduce Stress",
    href: "/by-real-life-applications/reduce-stress/",
  },
  {
    name: "Spiritual Growth",
    href: "/by-real-life-applications/spiritual-growth/",
  },
  {
    name: "Relaxation",
    href: "/by-real-life-applications/relaxation-stress-management/",
  },
  {
    name: "Self Improvement",
    href: "/by-real-life-applications/self-improvement/",
  },
  {
    name: "Stress Management",
    href: "/by-real-life-applications/stress-management/",
  },
  {
    name: "Dream Better",
    href: "/by-real-life-applications/expanded-awareness/",
  },
  {
    name: "Sleep Better",
    href: "/by-real-life-applications/sleep-dreams/",
  },
  {
    name: "Creativity",
    href: "/by-real-life-applications/creativity/",
  },
  {
    name: "Health Issues",
    href: "/by-real-life-applications/health-issues/",
  },
  {
    name: "Behavior Modification",
    href: "/by-real-life-applications/behavior-modification/",
  },
  {
    name: "Death & Dying",
    href: "/by-real-life-applications/death-dying/",
  },
  {
    name: "Problem Solving",
    href: "/by-real-life-applications/problem-solving/",
  },
  {
    name: "Concentration",
    href: "/by-real-life-applications/concentration/",
  },
  {
    name: "Learning & Memory",
    href: "/by-real-life-applications/learning-memory/",
  },
  {
    name: "Self-Confidence",
    href: "/by-real-life-applications/self-confidence/",
  },
  {
    name: "Spa & Yoga",
    href: "/by-real-life-applications/massage-body-work/",
  },
  {
    name: "Focused Attention",
    href: "/by-real-life-applications/focused-attention/",
  },
  {
    name: "Frustrations",
    href: "/by-real-life-applications/frustrations/",
  },
  {
    name: "Accelerated Learning and Memory",
    href: "/by-real-life-applications/accelerated-learning-and-memory/",
  },
  {
    name: "Shamanic",
    href: "/by-real-life-applications/shamanic/",
  },
  {
    name: "Heart-Centric",
    href: "/by-real-life-applications/heart-centric/",
  },
  {
    name: "Child & Baby",
    href: "/by-real-life-applications/children/",
  },
  {
    name: "ADD / ADHD",
    href: "/by-real-life-applications/add-adhd/",
  },
  {
    name: "Anger",
    href: "/by-real-life-applications/anger/",
  },
  {
    name: "Fitness & Sports",
    href: "/by-real-life-applications/fitness-sports/",
  },
  {
    name: "Weight Control",
    href: "/by-real-life-applications/weight-control/",
  },
  {
    name: "Financial Success",
    href: "/by-real-life-applications/financial-success/",
  },
  {
    name: "Sensory Improvement",
    href: "/by-real-life-applications/sensory-improvement/",
  },
  {
    name: "Out of Body",
    href: "/by-real-life-applications/out-of-body/",
  },
  {
    name: "Positively Ageless",
    href: "/by-real-life-applications/positively-ageless/",
  },
  {
    name: "Mind Food®",
    href: "/by-genre/mind-food/",
  },
  {
    name: "Metamusic®",
    href: "/by-genre/metamusic/",
  },
  {
    name: "Gateway Experience®",
    href: "/by-genre/gateway-experience/",
  },
  {
    name: "Album Series",
    href: "/by-genre/album-series/",
  },
  {
    name: "Human Plus®",
    href: "/by-genre/human-plus/",
  },
  {
    name: "Flying Man Records™",
    href: "/by-genre/flying-man-records/",
  },
  {
    name: "English",
    href: "/by-language/english/",
  },
  {
    name: "Japanese",
    href: "/by-language/japanese/",
  },
  {
    name: "German (Deutsch)",
    href: "/by-language/german-deutsch/",
  },
  {
    name: "Italian",
    href: "/by-language/italian/",
  },
  {
    name: "Spanish (Español)",
    href: "/by-language/spanish-espanol/",
  },
  {
    name: "French (Français)",
    href: "/by-language/french-francais/",
  },
  {
    name: "Hungarian (Magyar)",
    href: "/by-language/hungarian-magyar/",
  },
  {
    name: "Chinese (Mandarin)",
    href: "/by-language/chinese-mandarin/",
  },
  {
    name: "Română",
    href: "/by-language/romana/",
  },
  {
    name: "Greek",
    href: "/by-language/greek/",
  },
  {
    name: "Polish (Polski)",
    href: "/by-language/polish-polski/",
  },
];

const StoreBrowserHome = ({base} : {base: string}) => {
  return (
    <div>
      <h1>Store Browser Home</h1>

      <ul>
        {LINKS.map((link) => (
          <li key={link.href}>
            <Link to={`${base}${link.href}`}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StoreBrowserHome;
