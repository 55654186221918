import axios, { AxiosRequestHeaders } from "axios";
import { ChangeEventHandler, useEffect, useState } from "react";
import { IArtistListItem, ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";
import { artistsListFetcher } from "./ArtistsList";

export const fetcher: IApiFetcher = async ({
  artist_id,
  app_id,
  utoken,
  omitUserAgent = false,
}: {
  artist_id: number;
  app_id: number;
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<any>(
    `${HOSTNAME}${fetcher.url}/${artist_id}/${app_id}`,
    {
      headers,
    }
  );
  return response.data;
};
fetcher.url = "/wp-json/wp/v3/artists/artist_albums";
fetcher.method = 'GET';

const ArtistsArtistAlbums: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const [data, setData] = useState<string>("");
  const [artistId, setArtistId] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);
  const [artists, setArtists] = useState<IArtistListItem[]>([]);

  useEffect(() => {
    let isActive = true;
    if (!loginData?.token) return;

    artistsListFetcher({ utoken: loginData.token, omitUserAgent: true }).then(
      (data) => {
        if (isActive) {
          setArtists(data.data);
        }
      }
    );
    return () => {
      isActive = false;
    };
  }, [loginData]);

  const onFetch = () => {
    if (!loginData?.token) return;

    setIsLoading(true);
    fetcher({
      artist_id: +artistId,
      app_id: 0,
      utoken: loginData.token,
      omitUserAgent: true,
    }).then((data) => {
      setIsLoading(false);
      setData(JSON.stringify(data, null, 2));
    });
  }

  const onArtistChange: ChangeEventHandler<HTMLSelectElement> = e => {
    setArtistId(e.target.value);
  }

  return (
    <div>
      <h3>ArtistsArtistAlbums</h3>

      <select value={artistId} onChange={onArtistChange}>
        <option key="" value="">None</option>
        {artists.map((item) => (
          <option key={item.id} value={item.id}>{item.name}</option>
        ))}
      </select>
      <button onClick={onFetch}>Fetch</button>
      <pre>{loading ? "Loading..." : data}</pre>
    </div>
  );
};

ArtistsArtistAlbums.fetcher = fetcher;

export default ArtistsArtistAlbums;
