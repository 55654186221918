import axios, { AxiosRequestHeaders } from "axios";
import { FormEventHandler, useRef, useState } from "react";
import { ILoginData2 } from "../../../models";
import { HOSTNAME } from "../api-utils";
import { ApiTesterComponent, IApiFetcher } from "../ApiTesterApp";

export const tracksAlbumGet: IApiFetcher = async ({
  id,
  utoken,
  omitUserAgent = false,
}: {
  id: string,
  utoken: string;
  omitUserAgent?: boolean;
}) => {
  const headers: AxiosRequestHeaders = {};
  if (!omitUserAgent) {
    headers["User-Agent"] = "I:2.5.0(256)";
  }
  headers["utoken"] = utoken;

  const response = await axios.get<any>(`${HOSTNAME}${tracksAlbumGet.url}?id=${id}`, {
    headers,
    validateStatus: () => true
  });
  return response.data;
};
tracksAlbumGet.url = "/wp-json/wp/v3/tracks/album";
tracksAlbumGet.method = 'GET';

const TracksAlbum: ApiTesterComponent = ({
  loginData,
}: {
  loginData: ILoginData2 | null;
}) => {
  const albumIDRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!loginData?.token) return;
    if(!albumIDRef) return;

    setIsLoading(true);
    tracksAlbumGet({
      id: albumIDRef.current?.value,
      utoken: loginData.token,
      omitUserAgent: true,
    }).then((data) => {
      setIsLoading(false);
      setData(JSON.stringify(data, null, 2));
    });
  };

  return (
    <div>
      <h3 className="font-bold text-xl">TracksAlbum</h3>
      <form onSubmit={onSubmit} className="space-x-2 my-2">
        <label>Album ID</label>
        <input className="border-2 p-2" type="text" ref={albumIDRef} defaultValue="404390" />
        <button className="border p-2" type="submit">Fetch</button>
      </form>
      {loading && <div>Loading</div>}
      {!!data && !loading && <pre>{data}</pre> }
    </div>
  );
};

TracksAlbum.fetcher = tracksAlbumGet;

export default TracksAlbum;
